import React from "react";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Api from "../../../../services/http/api";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import IconButton from "@mui/material/IconButton";
import Image from "../../../../assets/img/profile.png"

const ReviewCell = ({avis}) => {
  
  return (
    <Box
      className="item-hover"
      sx={{
        position: "relative",
        display: "flex",
        alignItems: "center",
        // px: 4,
        py: 1,
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Avatar
          sx={{
            height: 60,
            width: 60,
            overflow: "hidden",
          }}
          alt="utilisateur"
          src={`${Api.baseUrl}/api/file?fileReference=${avis.grouilleur.photoProfile}`}
        />

        <Box
          sx={{
            flex: 1,
            ml: 2,
          }}
        >
          <Box
            component="h3"
            sx={{
              color: "text.primary",
              display: "flex",
              // mb: 2,
              fontWeight: 700,
              alignItems: "center",
              fontSize: 16,
            }}
          >
            {avis.grouilleur.nom} {avis.grouilleur.prenom}
          </Box>
          <Box
            sx={{
              fontSize: 16,
              color: "text.secondary",
              textAlign: "left",
            }}
          >
            {avis.contenu}
          </Box>
          <Box
            sx={{
              fontSize: 12,
              color: "text.hint",
              textAlign: "left",
            }}
          >
            {avis.dateCreation}
          </Box>
        </Box>
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          position: "absolute",
          bottom: 2,
          right: 2,
          flexDirection: "row",
          color: "#737989",
          alignItems: "center",
          fontSize: 14,
        }}
      >
        <IconButton style={{ marginRight: 5, height: 25, width: 25 }}>
          <ThumbUpAltOutlinedIcon style={{ fontSize: 16 }} />
        </IconButton>
        345
        <IconButton
          style={{ marginRight: 5, marginLeft: 15, height: 25, width: 25 }}
        >
          <ThumbDownAltOutlinedIcon style={{ fontSize: 16 }} />
        </IconButton>
        13
      </Box> */}
    </Box>
  );
};
export default ReviewCell;
