import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Divider, CircularProgress, Fab } from "@mui/material";
import CallIcon from "@mui/icons-material/Call";
import Logo from "../../assets/img/logo.png";
import ProfilePicture from "./components/ProfilePicture";
import Realisation from "./components/Realisation";
import ImageViewer from "./components/ImageViewer";
import Review from "./components/Reviews";
import Badges2 from "./components/Badges2";
import Api from "../../services/http/api";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DisplayContact from "./components/DisplayContact";
import { decryptUserId } from "../../services/encrypt";

const ProfileGrouilleur = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const { userId } = useParams(); // Get the userId from the URL
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const isXs = useMediaQuery(theme.breakpoints.down("sm")); // For xs screen sizes
  const decryptedUserId = decryptUserId(userId);
  

  const handleClose = () => {
    setSelectedIndex(null);
    setOpen(false);
  };
  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };

  const handleCloseContact = () => {
    setOpenContact(false);
  };
  const handleOpenContact = () => {
    setOpenContact(true);
  };

  useEffect(() => {
    if (decryptedUserId) {
      fetchUserProfile(decryptedUserId);
    } else {
      console.error("Decryption of userId failed.");
      setLoading(false);
    }
  }, [decryptedUserId]);

  const fetchUserProfile = async (userId) => {
    // Use the decrypted userId here
    try {
      const response = await fetch(
        `${Api.baseUrl}/api/mobile/v3/utilisateur/${userId}/profile`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch user profile");
      }
      const data = await response.json();
      console.log("userdata:", data.content);
      setUserData(data.content);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          bgcolor: "background.default",
          px: 2,
          py: 3,
        }}
      >
        <Box
          sx={{ maxWidth: "1000px", textAlign: { xs: "center", md: "left" } }}
        >
          {/* <Box sx={{ display: "flex", justifyContent: "space-between", alignItems:"center" }}> */}
          {/* <Typography
              sx={{
                fontWeight: 700,
                fontSize: "24px",
                my: 4,
              }}
            >
              Profil du Grouilleur
            </Typography> */}
          <Fab
            variant="extended"
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: isXs && 100,
              top: !isXs && 150,
              right: 16,
              textTransform: "none",
            }}
            onClick={handleOpenContact}
          >
            <CallIcon sx={{ mr: isXs ? 0 : 1 }} />
            {!isXs && "Contacter"}
          </Fab>
          <Box sx={{ pb: 5 }}>
            <img src={Logo} alt="Logo" style={{ height: "70px" }} />
          </Box>
          {/* </Box> */}

          <ProfilePicture user={userData} />

          <Box>
            <Badges2 badges={userData.badges} avis={userData.avis} />
            <Divider sx={{ my: 2 }} />
            <Realisation
              images={userData.realisations}
              handleOpen={handleOpen}
            />
            <ImageViewer
              open={open}
              close={handleClose}
              images={userData.realisations}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />

            <Review avis={userData.avis} />
            <DisplayContact
              open={openContact}
              handleClose={handleCloseContact}
              contact={userData.telephone}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ProfileGrouilleur;
