import React from "react";
import { Box, Typography } from "@mui/material";
import ReviewCell from "./ReviewCell";

const Review = ({ avis }) => {
  return (
    <Box>
      <Typography variant="h6" sx={{ fontWeight: "500", pt: 4 }}>
        Avis{" "}
      </Typography>
      {avis.length === 0 ? (
        <Typography  sx={{ fontWeight: "400", py: 2 }}>
          Aucun avis.
        </Typography>
      ) : (
        avis.map((item) => <ReviewCell key={item} avis={item} />)
      )}
    </Box>
  );
};

export default Review;
