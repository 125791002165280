import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, TextField } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CloseIcon from "@mui/icons-material/Close";
import CustomToast from "../../../../helpers/customToast";
import Api from "../../../../services/http/api";
import { encryptUserId } from "../../../../services/encrypt";

const WhatsAppWidget = ({ open, handleClose, grouilleur }) => {
  const [number, setNumber] = useState("");

  const handleInputChange = (event, callback) => {
    callback(event.target.value);
  };

  const handleMessage = () => {
    const text = encodeURIComponent(
      `Ouvrez ce lien pour voir le profile du grouilleur *${grouilleur.grouilleurFirstName} ${grouilleur.grouilleurLastName}*: `
    );

    // Create a link that includes the encrypted user data
    const encryptedId = encryptUserId(grouilleur.grouilleurIdUser);
    const encodedUserId = encodeURIComponent(encryptedId);    
    const url = `\n\n${Api.appUrl}/grouilleur/${encodedUserId}`;
    const link = encodeURIComponent(url);
    const phone = number.replace(/[^\w\s]/gi, "").replace(/ /g, "");

    // Create WhatsApp URL
    const whatsappUrl = `https://web.whatsapp.com/send?text=${text}${link}&phone=${phone}&app_absent=0`;

    if (window.whatsappWindow && !window.whatsappWindow.closed) {
      // If WhatsApp is already open, just focus on the existing tab
      window.whatsappWindow.focus();
    } else {
      window.whatsappWindow = window.open(whatsappUrl, "_blank");
    }
  };

  const handleSendMessage = () => {
    if (number.trim().length < 8) {
      CustomToast.showWarnin("Veuillez saisir un numéro de téléphone valide");
      return;
    }
    handleMessage();
    setNumber("");
  };

  return (
    <>
      <Dialog
        width="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { borderRadius: "15px" } }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: 5,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <WhatsAppIcon sx={{ fontSize: "50px", color: "green" }} />
            {"Partager le Profil du Grouilleur"}
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                py: 2,
                width: "100%",
              }}
            >
              <Box sx={{ width: 350, maxWidth: "100%" }}>
                <TextField
                  fullWidth
                  label="Entrer le numéro de téléhone"
                  id="numéro"
                  value={number}
                  onChange={(event) => handleInputChange(event, setNumber)}
                />
              </Box>
              <IconButton onClick={handleSendMessage}>
                <SendIcon
                  sx={{
                    fontSize: "50px",
                    color: number.length < 8 ? "" : "green",
                  }}
                />
              </IconButton>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default WhatsAppWidget;
